/*
 * @Descripttion:
 * @version:
 * @Author: Temiy
 * @Date: 2020-04-06 15:47:15
 * @LastEditors: Temiy
 * @LastEditTime: 2020-06-03 17:03:06
 */

import router from './index'

import { getToKen, removeToKen } from '../plugins/app'

const whiteRouter = ['/login'] // indexOf方法,判断数组中是否存在指定的某个对象,如果不存在,则返回-1
const publicRouter = [
  '/tvshow',
  '/solveshow',
  '/getsupport',
  '/404',
  '/repairPic',
  '/aspExam',
  '/exterior',
  '/see',
  '/appleTimeLine'
] // kaifang
// 路由守卫
router.beforeEach((to, from, next) => {
  // window.console.log(to)
  if (publicRouter.indexOf(to.path) !== -1) {
    next()
    return true
  }
  if (getToKen()) {
    // window.console.log('存在');
    if (to.path === '/login') {
      removeToKen() // 清楚token
      next()
    } else {
      next()
    }
  } else {
    // next('/login') // 路由指向
    // window.console.log('b存在');
    if (whiteRouter.indexOf(to.path) !== -1) {
      // 存在
      next() // 不带参数,不会再次执行 beforeEach方法
    } else {
      next('/login')
    }
  }
  // window.console.log(to)
  // window.console.log(from)
  // window.console.log(next)
  // next()
})
