/*
 * @Descripttion:
 * @version:
 * @Author: Temiy
 * @Date: 2020-06-15 12:45:13
 * @LastEditors: Temiy
 * @LastEditTime: 2020-07-09 14:40:17
 */

import axios from 'axios'
import router from '@/router'
import store from '@/store'

class axiosCom {
  //new 一下，立马就能使用
  constructor() {
    // //请求的路径
    // this.baseURL = Vue.prototype.$apiv1
    // this.timeout = 3000; //超时时间。3秒。
  }

  //自定义一个将传进来的参数和默认参数进行融合的方法
  merge(options) {
    //使用默认定义的baseURL 和 timeout参数
    return { ...options, baseURL: this.baseURL, timeout: this.timeout }
  }

  //自定义一个拦截器
  setInterceptor(instance) {
    //对请求进行拦截
    //更改请求头
    instance.interceptors.request.use((config) => {
      //可以修改请求头
      //在请求头里面，加token
      if (config.url !== 'LoginApi/login') {
        const AUTH_TOKEN = localStorage.getItem('token')
        // const USERNAME =localStorage.getItem('username');
        config.headers['Authorization'] = AUTH_TOKEN
      }

      store.commit('targetvloadingStatus', true)
      return config
    })

    //对响应后的数据，进行拦截
    //如果上一个promise， 返回了一个常量，会作为下一个promise的输入
    instance.interceptors.response.use((res) => {
      if (res.data.code === 401 || res.data.code === 410 || res.data.code === 411) {
        router.push({ name: 'Login' })
      }

      store.commit('targetvloadingStatus', false)

      return res.data
    })
  }

  request(config) {
    //创建实例的方式，来使用axios
    let instance = axios.create() //通过axios库创建一个axios实例
    // instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

    //设置拦截器
    this.setInterceptor(instance)
    // //设置参数。 将options和默认参数，进行融合。
    // let config = this.merge(options)
    //axios执行后返回的是一个promise
    return instance(config)
  }
}
export default new axiosCom()
