import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
const userAgent = navigator.userAgent.toLowerCase();
let mode = "history";
if (userAgent.indexOf(" electron/") > -1) {
  // alert('当前在electron中')
  // electron 必须为hash
  mode = "hash";
}

// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

/**
 * 重写路由的push方法
 */
// const routerPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push (location) {
//   return routerPush.call(this, location).catch(error => error)
// }

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    // name: "Home",
    component: Home,
    children: [
      // 这也是个路由记录
      {
        path: "",
        redirect: "index",
      },
      {
        path: "/index",
        name: "index",
        component: () => import("../views/Index/index.vue"),
      },
      {
        path: "PetList",
        name: "PetList",
        component: () => import("../views/Pet/PetList.vue"),
      },
      {
        path: "/repair",
        name: "repair",
        component: () => import("../views/Repair/repair.vue"),

        children: [
          {
            path: "detection",
            name: "detection",
            component: () => import("../views/Repair/detection.vue"),
          },

          {
            path: "synchronize",
            name: "synchronize",
            component: () => import("../views/Repair/synchronize.vue"),
          },
          {
            path: "out",
            name: "out",
            component: () => import("../views/Repair/out.vue"),
          },
          {
            path: "maintenance",
            name: "maintenance",
            component: () => import("../views/Repair/maintenance.vue"),
          },
          {
            path: "in",
            name: "in",
            component: () => import("../views/Repair/in.vue"),
          },
          {
            path: "informfetch",
            name: "informfetch",
            component: () => import("../views/Repair/informfetch.vue"),
          },

          {
            path: "visit",
            name: "visit",
            component: () => import("../views/Repair/visit.vue"),
          },
        ],
      },
      {
        path: "weekStuday",
        name: "weekStuday",
        component: () => import("../views/DataBase/weekStuday.vue"),
      },

      {
        path: "weekStudayInfo",
        name: "weekStudayInfo",
        component: () => import("../views/DataBase/weekStudayInfo.vue"),
      },
      {
        path: "permission",
        name: "permission",
        component: () => import("../views/DataBase/permission.vue"),
      },

      {
        path: "frontAccept",
        name: "frontAccept",
        component: () => import("../views/Repair/frontAccept.vue"),
      },
      {
        path: "createNewRepair",
        name: "createNewRepair",
        component: () => import("../views/Repair/createNewRepair.vue"),
      },
      {
        path: "outTest",
        name: "outTest",
        component: () => import("../views/Repair/outTest.vue"),
      },

      {
        path: "/repairList",
        name: "repairList",
        component: () => import("../views/Repair/repairList.vue"),
      },
      {
        path: "/resolutionLst",
        name: "resolutionLst",
        component: () => import("../views/Repair/resolutionLst.vue"),
      },
      {
        path: "/servicePriceLst",
        name: "servicePriceLst",
        component: () => import("../views/DataBase/servicePriceLst.vue"),
      },

      {
        path: "/waitFor",
        name: "waitFor",
        component: () => import("../views/Repair/waitFor.vue"),
      },
      {
        path: "/waitForOut",
        name: "waitForOut",
        component: () => import("../views/Repair/waitForOut.vue"),
      },
      {
        path: "/software",
        name: "software",
        component: () => import("../views/DataBase/software.vue"),
      },

      {
        path: "/timeEffect",
        name: "timeEffect",
        component: () => import("../views/DataBase/timeEffect.vue"),
      },
      {
        path: "/publishBlog",
        name: "publishBlog",
        component: () => import("../views/DataBase/publishBlog.vue"),
      },
      {
        path: "/blogInfo/:blogId",
        name: "blogInfo",
        component: () => import("../views/DataBase/blogInfo.vue"),
      },
      {
        path: "/priceSearch",
        name: "priceSearch",
        component: () => import("../views/DataBase/priceSearch.vue"),
      },
      {
        path: "/faultList",
        name: "faultList",
        component: () => import("../views/DataBase/faultList.vue"),
      },
      {
        path: "/partCodeList",
        name: "partCodeList",
        component: () => import("../views/DataBase/partCodeList.vue"),
      },
      {
        path: "/kankan",
        name: "kankan",
        component: () => import("../views/DataBase/kankan.vue"),
      },
      {
        path: "/kankan/upload",
        name: "kkupload",
        component: () => import("../views/DataBase/kkupload.vue"),
      },

      {
        path: "/learn/:id",
        name: "learn",
        component: () => import("../views/DataBase/learn.vue"),
      },

      {
        path: "/setting",
        name: "setting",
        component: () => import("../views/DataBase/setting.vue"),
      },
      {
        path: "/setting/tvshowSet",
        name: "tvshowSet",
        component: () => import("../views/DataBase/tvshowSet.vue"),
      },

      {
        path: "/escSet",
        name: "escSet",
        component: () => import("../views/DataBase/escSet.vue"),
      },
    ],
  },

  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login/index.vue"),
  },
  {
    path: "/see",
    name: "see",
    component: () => import("../views/See/see.vue"),
  },
  {
    path: "/tvshow",
    name: "tvshow",
    component: () => import("../views/Public/tvshow.vue"),
  },
  {
    path: "/appleTimeLine",
    name: "appleTimeLine",
    component: () => import("../views/Public/appleTimeLine.vue"),
  },

  {
    path: "/solveshow",
    name: "solveshow",
    component: () => import("../views/Public/solveshow.vue"),
  },
  {
    path: "/exterior",
    name: "exterior",
    component: () => import("../views/Public/exterior.vue"),
  },
  {
    path: "/aspExam",
    name: "aspExam",
    component: () => import("../views/Public/aspExam.vue"),
  },
  {
    path: "/getsupport",
    name: "getsupport",
    component: () => import("../views/Public/getsupport.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/Public/404.vue"),
  },

  {
    path: "/repairPic",
    name: "repairPic",
    component: () => import("../views/Public/repairPic.vue"),
  },
  {
    path: "/qiniutest",
    name: "qiniutest",
    component: () => import("../experiment/qiniutest.vue"),
  },
];
const router = new VueRouter({
  mode: mode,
  routes,
});

export default router;
