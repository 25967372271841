<template>
  <el-container>
    <el-header height="40px">
      <Header />
    </el-header>
    <el-container>
      <el-aside style="width:48px;margin-top:10px;" class="hidden-sm-and-down">
        <Aside />
      </el-aside>
      <el-main v-loading="vloadingState">
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Header from '@/components/Header.vue'
// import H2 from '@/components/H2.vue'
import Aside from '@/components/Aside.vue'

//  判断当前运行环境

var userAgent = navigator.userAgent.toLowerCase()

export default {
  components: {
    Header,
    // H2,
    Aside
  },
  created() {
    // 获取通知信息
    this.asd()

    // const { remote } = require('electron')
    // remote.getCurrentWindow().setSize(1280, 860)
  },
  data() {
    return {
      // isCollapse: true,
    }
  },

  //监听属性 类似于data概念
  computed: {
    vloadingState() {
      return this.$store.state.vloadingStatus
    }
  },

  methods: {
    // 获取提醒
    getAlert() {
      // console.log('查询了一次')

      this.$api1.get('v1/alert').then((res) => {
        // 处理返回数据
        const { data, code, message } = res

        if (code === 200) {
          // console.log(data)
          // 有数据
          data.forEach((item, index) => {
            // 提醒
            setTimeout(this.notMessage, 2000 * index, item)
          })
          //返回提醒
          this.reAlertStatus(data)
        }
      })
    },

    // 返回提醒状态
    reAlertStatus(data) {
      const arr = JSON.stringify(data)
      let params = { resData: arr }
      this.$api1.put('v1/alert/799', params)
    },

    // 发送提醒
    notMessage(data) {
      if (userAgent.indexOf(' electron/') > -1) {
        let myNotification = new Notification(data.title, {
          body: data.alert_text
        })
        myNotification.onclick = () => {
          this.$router.push({ name: 'resolutionLst' })
        }
      } else {
        const h = this.$createElement
        this.$notify({
          title: data.title,
          message: h('p', [
            h(
              'p',
              {
                on: {
                  click: () => {
                    this.$router.push({ name: 'resolutionLst' })
                  }
                }
              },
              data.alert_text
            )
          ]),
          type: data.style,
          duration: 0
        })
      }
    },
    // 循环执行
    asd() {
      let self = this
      // 判断是否工作日
      const dt = new Date()
      if (dt.getDay() !== 0 && dt.getDay() !== 6) {
        //0--sunday, 6--saturday
        // 判断是否 在工作时间段 9-18
        if (dt.getHours() >= 9 && dt.getHours() <= 18) {
          // 获取提醒
          this.getAlert()
        }
      }
      setTimeout(() => {
        self.asd()
      }, 1000 * 60 * 10)
    }
  }
}
</script>

<style lang="scss" scoped>
/* body{
  -webkit-app-region: drag;
} */
.el-header {
  background-color: #303133;
  color: #9d9d9d;
  text-align: left;
  //   align-items: center;
  padding-top: 8px;
  -webkit-app-region: drag;
}
.el-main {
  background-color: #fff;
  color: #333;
  text-align: center;
  // line-height: 160px;
}
</style>
