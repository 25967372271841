/*
 * @Descripttion:
 * @version:
 * @Author: Temiy
 * @Date: 2020-06-15 12:45:13
 * @LastEditors: Temiy
 * @LastEditTime: 2020-07-09 14:29:03
 */

import axios from 'axios'
import Vue from 'vue'

class publicApi {
  get(url, params = null) {
    let instance = axios.create() //通过axios库创建一个axios实例
    instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

    const config = {
      baseURL: Vue.prototype.$apiv1,
      url: url,
      method: 'get',
      params: params
    }

    return instance.request(config)
  }
}
export default new publicApi()
