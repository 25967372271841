/*
 * @Descripttion:
 * @version:
 * @Author: Temiy
 * @Date: 2020-06-15 10:10:15
 * @LastEditors: Temiy
 * @LastEditTime: 2020-06-15 14:00:24
 */

import axios from '@/network/axiosCom'
import Vue from 'vue'

class api1 {
  // constructor() {
  //   //请求的路径
  //   this.baseURL = Vue.prototype.$apiv1
  //   // this.timeout = 3000; //超时时间。3秒。
  // }

  get(url, params = null) {
    const config = {
      baseURL: Vue.prototype.$apiv1,
      url: url,
      method: 'get',
      params: params
    }

    return axios.request(config)
  }

  post(url, data) {
    const config = {
      baseURL: Vue.prototype.$apiv1,
      url: url,
      method: 'post',
      data: data
    }
    return axios.request(config)
  }

  put(url, data = null, params = null) {
    const config = {
      baseURL: Vue.prototype.$apiv1,
      url: url,
      method: 'put',
      data: data,
      params: params
    }

    return axios.request(config)
  }

  delete(url, data = null, params = null) {
    const config = {
      baseURL: Vue.prototype.$apiv1,
      url: url,
      method: 'delete',
      data: data,
      params: params
    }

    return axios.request(config)
  }
}

// export function request(config) {
//   const instance = axios.create({
//     baseURL: Vue.prototype.$apiv1,
//     timeout: 1000
//     //   headers: { 'X-Custom-Header': 'foobar' }
//   })

//   return instance(config)
// }
export default new api1()
