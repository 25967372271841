/*
 * @Descripttion:
 * @version:
 * @Author: Temiy
 * @Date: 2020-04-06 13:42:41
 * @LastEditors: Temiy
 * @LastEditTime: 2024-10-22 16:39:18
 */

import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "element-ui/lib/theme-chalk/display.css";
import "normalize.css/normalize.css";
import "./router/premit";

// import axios from 'axios'

import MyHttpServer from "./plugins/http.js";
import api1 from "@/network/apiV1";
import publicApi from "@/network/publicApi";

// import vueApi from '@/network/VueApi'

import VueQuillEditor from "vue-quill-editor";
import "./assets/iconfont/iconfont.css"; // 阿里图标
import moment from "moment";
import md5 from "js-md5";
import Print from "@/plugins/print"; // 引入附件的js文件
// import Print from 'vue-print-nb'

Vue.prototype.$host = process.env.VUE_APP_BASE_URL; // 服务器路径常量
Vue.prototype.$apiv1 = process.env.VUE_APP_API_URL; // apiV1路径常量
Vue.prototype.$qiniu = process.env.VUE_APP_QINIU_URL; // 七牛云外链路径常量

Vue.prototype.$md5 = md5;

Vue.prototype.$moment = moment; //赋值使用
Vue.prototype.$api1 = api1; //
Vue.prototype.$publicApi = publicApi; //

// Vue.prototype.$vueApi = vueApi //

Vue.use(ElementUI);
Vue.use(MyHttpServer);

Vue.use(Print); // 注册打印

// Vue.use(HttpServerV1)
Vue.use(VueQuillEditor);
Vue.use(require("vue-moment"));

// 引入公共方法
import commonFun from "@/plugins/common.js";
Vue.prototype.$commonFun = commonFun;

// var userAgent = navigator.userAgent.toLowerCase()
// if (userAgent.indexOf(' electron/') > -1) {
//   // alert('当前在app中')
//   if (!process.env.IS_WEB) Vue.use(require('vue-electron'))
//   // 关闭 electron 控制台的安全警告
//   process.env['ELECTRON_DISABLE_SECURITY_WARNINGS'] = 'true'
// }

// const { shell } = require('electron');

// const links = document.querySelectorAll('a[href]');
// links.forEach(link => {
//     link.addEventListener('click', e => {
//         const url = link.getAttribute('href');
//         e.preventDefault();
//         shell.openExternal(url);
//     });
// });

Vue.config.productionTip = false;

// 定义全局过滤器
Vue.filter("timerFilter", function (datetime) {
  if (!datetime) return "";

  let mistiming = Math.round(moment().unix() - moment(datetime).unix());
  const postfix = mistiming > 0 ? "前" : "后";
  mistiming = Math.abs(mistiming);
  const arrr = ["年", "个月", "星期", "天", "小时", "分钟", "秒"];
  const arrn = [31536000, 2592000, 604800, 86400, 3600, 60, 1];

  for (let i = 0; i < arrn.length; i++) {
    let inm = Math.floor(mistiming / arrn[i]);
    if (inm != 0) {
      return inm + arrr[i] + postfix;
    }
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
