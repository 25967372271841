<template>
  <el-row type="flex" justify="space-between" align="top">
    <el-col :xs="0" :sm="1" :md="2" :lg="1" class="redButton"></el-col>
    <el-col :xs="1" :sm="1" :md="0" class="navButton">
      <el-link @click="drawer = true">
        <i class="el-icon-s-unfold"></i>
      </el-link>
    </el-col>
    <el-col :xs="5" :sm="2" :md="2" :lg="2" class="logo">
      <router-link to="/">
        <el-link style="font-size:15px;">落落</el-link>
      </router-link>
    </el-col>
    <el-col :xs="1" :sm="3" :md="3" :lg="5">
      &nbsp;
      <!-- 隐藏导航 -->
      <el-drawer title="我是标题" :visible.sync="drawer" direction="ltr" :with-header="false" size="185px">
        <!-- 导航 -->
        <el-menu :default-openeds="[]" :router="true" :unique-opened="true">
          <NavCommon />
        </el-menu>
        <!-- 导航end -->
      </el-drawer>
      <!-- 隐藏导航 -->
    </el-col>
    <el-col :xs="11" :sm="12" :md="14" :lg="14" class="search">
      <el-input
        @keyup.enter.native="search()"
        size="mini"
        placeholder="请输入内容"
        v-model="inputSearchText"
        v-show="inputSearchButtonState"
      >
        <i slot="suffix" class="el-input__icon el-icon-search" @click="search()"></i>
      </el-input>
    </el-col>
    <el-col :xs="6" :sm="6" :md="4" :lg="4" class="right-con">
      <!-- :is-dot="resolutionInfoTotal>0?true:false" -->

      <el-badge :value="resolutionInfoTotal" class="remind-item">
        <i class="el-icon-message-solid" @click="resolutionLstRout"></i>
      </el-badge>
      <el-dropdown>
        <el-avatar :size="25" :src="$host + '/uploads/login/' + username + '.jpg'"></el-avatar>
        <!-- <i class="el-icon-setting" style="margin-right: 15px"></i> -->
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <el-row type="flex" justify="space-between">
              <el-col :span="6" class="avat-con">
                <el-avatar :size="45" :src="$host + '/uploads/login/' + username + '.jpg'"></el-avatar>
              </el-col>
              <el-col :span="16">
                <el-row>
                  <el-col :span="24">{{ nickname }}</el-col>
                  <el-col :span="24">{{ job }}</el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-dropdown-item>
          <el-dropdown-item>新增</el-dropdown-item>
          <el-dropdown-item>
            <div @click="handleSignout()">注销</div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-col>
    <!-- <el-col :span="2">头像</el-col> -->
  </el-row>
</template>

<script>
import NavCommon from '@/components/NavCommon.vue'

export default {
  components: {
    NavCommon
  },
  data() {
    return {
      inputSearchText: '',
      inputSearchButtonState: true,
      // isCollapse: true,
      drawer: false,

      resolutionInfoTotal: null,
      nickname: localStorage.getItem('nickname'),
      username: localStorage.getItem('username'),
      job: localStorage.getItem('job')
    }
  },
  created() {
    this.setDefultValue()
  },
  watch: {
    $route() {
      this.setDefultValue()
    }
  },
  methods: {
    showInputSearch() {
      this.inputSearchButtonState = false
    },
    search() {
      // alert(this.$route.path);
      const thisPath = this.$route.path
      if (thisPath === '/repairList') {
        this.$message.warning('请使用下方的搜索框搜索')
        this.inputSearchButtonState = true
        this.inputSearchText = ''
        return true
      }

      // 获取搜索内容
      const inputSearchText = this.inputSearchText
      if (!inputSearchText) {
        this.$message.error('请输入搜索内容')
        return true
      }
      // console.log(inputSearchText);

      this.$http.get('/v1/aspinfo/search/' + inputSearchText).then((res) => {
        // 处理返回数据
        const resData = res.data
        const code = resData.code
        const message = resData.message

        // console.log(resData);
        if (code === 200) {
          const searchData = resData.data
          const listData = searchData.listData
          // console.log(listData);
          // 判断内容是否存在
          if (listData.total === 0) {
            this.$message.error('暂无数据')
            return true
          }

          // 跳转页面
          this.$router.push({
            name: 'repairList',
            params: { searchData: searchData }
          })
        } else {
          this.$message.error(message)
          return true
        }
      })

      // 处理结果
    },
    // 跳转待处理
    resolutionLstRout() {
      this.$router.push({ name: 'resolutionLst' })
    },
    handleSignout() {
      // alert("ee");
      // 1.清除token
      // localStorage.clear();

      // 2.提示
      this.$message.success('退出成功')

      // 3. 来到login
      this.$router.push({ name: 'Login' })
    },
    setDefultValue() {
      // 获取待处理数量
      this.$api1.get('/v1/resolution').then((res) => {
        // 处理返回数据
        const { data, code, message } = res

        // console.log(resData)
        if (code === 200) {
          const resolutionInfoTotal = data
          // console.log(listData);
          // 判断内容是否存在
          if (resolutionInfoTotal === 0) {
            this.resolutionInfoTotal = null
          } else {
            this.resolutionInfoTotal = resolutionInfoTotal
          }
        } else {
          this.$message.error(message)
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.router-link-active {
  text-decoration: none;
}
.el-link.el-link--default {
  margin-left: 20px;
  color: #929294;
}

.el-link.el-link--default:hover {
  color: #eee;
}

.redButton {
  max-width: 200px;
  // background-color: blue;
}
.navButton {
  padding-top: 5px;
}
.logo {
  text-align: left;
  padding-top: 5px;
  // background-color: yellow;
}
.search {
  max-width: 400px;
  text-align: center;

  // background-color: orange;
}
.right-con {
  text-align: right;

  // background-color: red;

  .remind-item {
    margin-right: 20px;
    padding-bottom: 10px;
  }
  .avat-con {
    padding: 5px;
  }
}
</style>
