// function convertBase64UrlToBlob(urlData) {
//   const bytes = window.atob(urlData.split(',')[1]) // 去掉url的头，并转换为byte // 处理异常,将ascii码小于0的转换为大于0
//   const ab = new ArrayBuffer(bytes.length)
//   const ia = new Uint8Array(ab)
//   for (let i = 0; i < bytes.length; i++) {
//     ia[i] = bytes.charCodeAt(i)
//   }
//   return new Blob([ab], { type: 'image/png' })
// }
import Vue from 'vue'
import api1 from '@/network/apiV1'
import router from '@/router'
import { Message } from 'element-ui'

// import MyHttpServer from '@/plugins/http.js'

function dataURItoBlob(dataURI, type) {
  var binary = atob(dataURI.split(',')[1])
  var array = []
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i))
  }
  return new Blob([new Uint8Array(array)], { type: type })
}

function makePicUrlWithImageNameList(imageNameList, filePath) {
  let picUrlList = new Array()
  let url = null
  // console.log(imageNameList)
  imageNameList.forEach((item) => {
    const save_sort = item.save_sort
    switch (save_sort) {
      case 'qiniu':
        url = Vue.prototype.$qiniu + '/' + item.image_name

        break

      default:
        url = Vue.prototype.$host + '/uploads/bigfilelocal/' + filePath + '/' + item.return_time + '/' + item.image_name

        break
    }
    picUrlList.push(url)
  })
  // console.log(picUrlList)
  return picUrlList
}

export default {
  Base64: {
    //加密
    encode(str) {
      return btoa(
        encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
          return String.fromCharCode('0x' + p1)
        })
      )
    },
    //解密
    decode(str) {
      // Going backwards: from bytestream, to percent-encoding, to original string.
      return decodeURIComponent(
        atob(str)
          .split('')
          .map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
          })
          .join('')
      )
    }
  },
  //  图片压缩，默认同比例压缩
  //  @param {Object} fileObj
  //  图片对象
  //  回调函数有一个参数，base64的字符串数据

  // compress(fileObj, callback) {
  //   try {
  //     const image = new Image()
  //     image.src = URL.createObjectURL(fileObj)
  //     image.onload = function() {
  //       const that = this // 默认按比例压缩
  //       let w = that.width
  //       let h = that.height
  //       const scale = w / h
  //       w = fileObj.width || w
  //       h = fileObj.height || w / scale
  //       let quality = 0.7 // 默认图片质量为0.7 // 生成canvas
  //       const canvas = document.createElement('canvas')
  //       const ctx = canvas.getContext('2d') // 创建属性节点
  //       const anw = document.createAttribute('width')
  //       anw.nodeValue = w
  //       const anh = document.createAttribute('height')
  //       anh.nodeValue = h
  //       canvas.setAttributeNode(anw)
  //       canvas.setAttributeNode(anh)
  //       ctx.drawImage(that, 0, 0, w, h) // 图像质量
  //       if (fileObj.quality && fileObj.quality <= 1 && fileObj.quality > 0) {
  //         quality = fileObj.quality
  //       } // quality值越小，所绘制出的图像越模糊
  //       const data = canvas.toDataURL('image/jpeg', quality) // 压缩完成执行回调
  //       const newFile = convertBase64UrlToBlob(data)
  //       callback(newFile)
  //     }
  //   } catch (e) {
  //     console.log('压缩失败!')
  //   }
  // },

  //  图片压缩，默认同比例压缩

  compress(file, imgQuality, resolve) {
    let isLt1M = file.size / 1024 / 1024 < 1 // 判定图片大小是否小于1MB
    if (isLt1M) {
      resolve(file)
    }
    const reader = new FileReader()
    const image = new Image()
    image.onload = (imageEvent) => {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      const width = image.width * imgQuality
      const height = image.height * imgQuality
      canvas.width = width
      canvas.height = height
      context.clearRect(0, 0, width, height)
      context.drawImage(image, 0, 0, width, height)
      const dataUrl = canvas.toDataURL(file.type)
      const blobData = dataURItoBlob(dataUrl, file.type)
      resolve(blobData)
    }
    reader.onload = (e) => {
      image.src = e.target.result
    }
    reader.readAsDataURL(file)
  },

  // 获取文件名后缀
  extname(filename) {
    if (!filename || typeof filename != 'string') {
      return false
    }
    let a = filename
      .split('')
      .reverse()
      .join('')
    let b = a
      .substring(0, a.search(/\./))
      .split('')
      .reverse()
      .join('')
    return b
  },
  // 快速复制
  quickCopyWithValue(value) {
    if (!value) {
      return
    }

    const input = document.createElement('input') // 直接构建input
    input.value = value // 设置内容
    document.body.appendChild(input) // 添加临时实例
    input.select() // 选择实例内容
    document.execCommand('Copy') // 执行复制
    document.body.removeChild(input) // 删除临时实例
  },
  // 转图片url
  makePicUrlFromImageNameList(imageNameList, filePath) {
    let picUrlList = new Array()
    let url = null
    // console.log(imageNameList)
    imageNameList.forEach((item) => {
      const save_sort = item.save_sort
      switch (save_sort) {
        case 'qiniu':
          url = Vue.prototype.$qiniu + '/' + item.image_name

          break
        case 'tvshow':
          url = Vue.prototype.$host + '/uploads/bigfilelocal/tv_show/' + item.sort + '/' + item.image_name
          break
        default:
          url =
            Vue.prototype.$host + '/uploads/bigfilelocal/' + filePath + '/' + item.return_time + '/' + item.image_name

          break
      }
      picUrlList.push(url)
    })
    // console.log(picUrlList)
    return picUrlList
  },

  // 查询送修图片URL
  async getRepairPicUrl(formId, filePath) {
    return await api1.get('/v1/aspinfo/repairpic/' + formId + '/' + filePath).then((res) => {
      // 处理返回数据
      const { data, code } = res

      if (code !== 200) {
        return null
      }
      const imageNameList = data
      return makePicUrlWithImageNameList(imageNameList, filePath)
    })
  },

  // 处理基础返回请求
  checkRes(res) {
    const { data, code, message } = res
    if (code === 401 || code === 410 || code === 411) {
      Message.error(message)
      router.push({ name: 'Login' })
    }

    return res
  }

  // 测试请求
  // async testGet() {
  //   return await api1.get('/v1/timeeffect').then((res) => {
  //     return 'ok'
  //   })
  //   // return Vue.prototype.$host
  // }
}
