<template>
  <!-- 导航 -->
  <el-menu :router="true" @open="handleOpen" @close="handleClose" :collapse="isCollapse">
    <NavCommon />
  </el-menu>
  <!-- 导航end -->
</template>

<script>
import NavCommon from '@/components/NavCommon.vue'
export default {
  components: {
    NavCommon
  },
  data() {
    return {
      isCollapse: true

      //   direction: 'ltr',
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      window.console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      window.console.log(key, keyPath)
    }
  }
}
</script>

<style></style>
