/*
 * @Descripttion:
 * @version:
 * @Author: Temiy
 * @Date: 2020-03-14 22:25:44
 * @LastEditors: Temiy
 * @LastEditTime: 2020-06-23 10:41:57
 */

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    addSNButtonStatus: false,
    addPDButtonStatus: false,
    repairPicStatus: false,
    vloadingStatus: false
  },
  mutations: {
    targetAddSNButtonStatus(state, bool) {
      state.addSNButtonStatus = bool
    },
    targetAddPDButtonStatus(state, bool) {
      state.addPDButtonStatus = bool
    },
    targetRepairPicStatus(state, bool) {
      state.repairPicStatus = bool
    },
    targetvloadingStatus(state, bool) {
      state.vloadingStatus = bool
    }
  },
  actions: {},
  modules: {}
})
