export function getToKen() {
  return localStorage.getItem('token')
}

export function removeToKen() {
  return localStorage.clear()
}

export function comTest() {
  console.log('1111')
}
