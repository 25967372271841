/*
 * @Descripttion:
 * @version:
 * @Author: Temiy
 * @Date: 2020-04-06 15:22:43
 * @LastEditors: Temiy
 * @LastEditTime: 2020-07-09 14:35:00
 */

// 插件模块

import axios from 'axios'
import router from '@/router'
const MyHttpServer = {}

MyHttpServer.install = (Vue) => {
  // 开发网络
  // axios.defaults.baseURL = Vue.prototype.$host + '/api'
  axios.defaults.baseURL = Vue.prototype.$apiv1
  axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
  // 添加请求拦截器
  axios.interceptors.request.use(
    function(config) {
      // 在发送请求之前做些什么
      // window.console.log('拦截器触发')

      // window.console.log(config)

      if (config.url !== 'LoginApi/login') {
        const AUTH_TOKEN = localStorage.getItem('token')
        // const USERNAME =localStorage.getItem('username');
        config.headers['Authorization'] = AUTH_TOKEN
        // config.headers['UserName'] =USERNAME;
      }
      return config
    },
    function(error) {
      // 对请求错误做些什么
      return Promise.reject(error)
    }
  )

  // 添加响应拦截器
  axios.interceptors.response.use(
    (response) => {
      // 对响应数据做点什么
      if (response.data.code === 304) {
        // Storage.localRemove('token') // 删除已经失效或过期的token（不删除也可以，因为登录后覆盖）

        // 提示
        // Message.error('信息错误,请重新登入')
        router.push({ name: 'Login' })
      } else if (response.data.code === 305) {
        // 登入信息失效
        // 提示
        // Message.error('登入信息失效,请重新登入')
        router.push({ name: 'Login' })
      }

      return response
    },
    function(error) {
      // 对响应错误做点什么
      return Promise.reject(error)
    }
  )

  // 添加实例方法
  Vue.prototype.$http = axios
}

export default MyHttpServer
